<template>
  <v-dialog v-model="showDialog" max-width="470" min-width="470">
    <v-card class="py-8 px-6">
      <v-card-title class="pa-0 text-sm-h3">
        {{ title }}
      </v-card-title>
      <v-card-text class="pa-0 mt-3 mb-8 body-1">
        {{ body }}
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-row no-gutters>
          <v-spacer />
          <v-col cols="auto" align-self="center" class="mr-4">
            <BaseButtonTertiary small @click="close" :disabled="isLoading">
              {{ $t("DELETE_ACTION.BUTTONS.CANCEL") }}
            </BaseButtonTertiary>
          </v-col>
          <v-col cols="auto" align-self="center">
            <BaseButton
              small
              @click="deleteObject"
              :disabled="isLoading"
              color="error"
              class="mr-4"
            >
              {{ $t("DELETE_ACTION.BUTTONS.CONFIRM") }}
            </BaseButton>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteActionDialog",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: null,
    },
    body: {
      type: String,
      required: true,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showDialog: this.value,
    };
  },

  watch: {
    value(value) {
      this.showDialog = value;
    },
    showDialog(value) {
      this.$emit("input", value);
    },
  },

  methods: {
    close() {
      this.showDialog = false;
    },
    deleteObject() {
      this.$emit("confirm");
      this.close();
    },
  },
};
</script>
