<template>
  <div>
    <v-tooltip v-if="icon" left>
      <template #activator="{ on, attrs }">
        <v-btn icon @click="open" v-bind="attrs" v-on="on">
          <v-icon small>far fa-trash</v-icon>
        </v-btn>
      </template>
      <span>{{ tooltipMessage }}</span>
    </v-tooltip>

    <BaseButton v-else @click="open" color="error">
      {{ $t("DELETE_ACTION.BUTTONS.OPEN") }}
    </BaseButton>

    <delete-action-dialog
      v-model="showDialog"
      :title="title"
      :body="body"
      :is-loading="isLoading"
      @confirm="confirm"
    />
  </div>
</template>

<script>
import i18n from "@/i18n";
import DeleteActionDialog from "./partials/DeleteActionDialog";
export default {
  name: "DeleteAction",
  components: { DeleteActionDialog },
  props: {
    objectId: {
      type: Number,
      required: true,
      default: null,
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: () => i18n.t("DELETE_ACTION.TOOLTIP_MESSAGE"),
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: null,
    },
    body: {
      type: String,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      showDialog: false,
    };
  },

  methods: {
    open() {
      this.showDialog = true;
    },
    confirm() {
      this.$emit("confirm", this.objectId);
    },
  },
};
</script>
