<template>
  <v-tooltip v-if="icon" left>
    <template #activator="{ on, attrs }">
      <v-btn icon @click="goToPage" v-bind="attrs" v-on="on">
        <v-icon small>{{ iconName }}</v-icon>
      </v-btn>
    </template>

    <span>{{ actionName }}</span>
  </v-tooltip>
  <BaseButtonTertiary v-else-if="tertiary" :color="buttonColor" @click="goToPage">
    <v-icon v-if="showButtonIcon" left small>
      {{ iconName }}
    </v-icon>

    {{ actionName }}
  </BaseButtonTertiary>

  <BaseButton v-else :color="buttonColor" @click="goToPage">
    <v-icon v-if="showButtonIcon" left small>
      {{ iconName }}
    </v-icon>

    {{ actionName }}
  </BaseButton>
</template>

<script>
export default {
  name: "GoToPageButtonIcon",
  props: {
    viewName: {
      type: String,
      required: true,
      default: null,
    },

    objectId: {
      type: Number,
      required: false,
      default: null,
    },

    actionName: {
      type: String,
      required: false,
      default: null,
    },

    iconName: {
      type: String,
      required: true,
      default: null,
    },

    icon: {
      type: Boolean,
      required: false,
      default: false,
    },

    tertiary: {
      type: Boolean,
      required: false,
      default: false,
    },

    showButtonIcon: {
      type: Boolean,
      required: false,
      default: false,
    },

    buttonColor: {
      type: String,
      required: false,
      default: () => "primary",
    },
  },

  methods: {
    async goToPage() {
      if (this.objectId !== null) {
        try {
          await this.$router.push({
            name: this.viewName,
            params: { id: this.objectId.toString() },
          });
        } catch {
          return;
        }
      } else {
        try {
          await this.$router.push({ name: this.viewName });
        } catch {
          return;
        }
      }
    },
  },
};
</script>
