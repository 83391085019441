<template>
  <ModuleSubPage fullWidth title="USER_MANAGEMENT.USER_INVITATIONS.SHOW.PAGE_TITLE">
    <div v-if="!isLoading">
      <BaseHasPermission :permissionNames="['invitation.read']">
        <v-card flat color="transparent">
          <v-card-text class="pa-0">
            <v-row>
              <v-col cols="12">
                <BaseBackButton :route="{ name: 'management-user-invitation-list' }">
                  {{ $t("USER_MANAGEMENT.USER_INVITATIONS.SHOW.BTN_LABEL_BACK") }}
                </BaseBackButton>
              </v-col>
            </v-row>

            <v-row class="mb-12">
              <v-col cols="12">
                <div class="mb-2">
                  <BaseText overline color="gray-600">
                    {{ $t("USER_MANAGEMENT.USER_INVITATIONS.SHOW.LABELS.EMAIL") }}
                  </BaseText>
                </div>

                <BaseText>
                  {{ userInvitation.email }}
                </BaseText>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="pa-0">
            <AssignRolesTable v-model="userInvitation.roles" class="pt-3" readonly />
          </v-card-text>

          <v-card-actions class="pt-6 px-3">
            <v-spacer />
            <DeleteAction
              :object-id="userInvitationId"
              :title="$t('USER_MANAGEMENT.USER_INVITATIONS.DELETE_ACTION.TITLE')"
              :body="$t('USER_MANAGEMENT.USER_INVITATIONS.DELETE_ACTION.BODY')"
              @confirm="deleteUserInvitation"
              class="mr-4"
            />

            <GoToPageButtonIcon
              view-name="management-user-invitation-edit"
              :object-id="userInvitationId"
              :action-name="$t('USER_MANAGEMENT.USER_INVITATIONS.EDIT.ACTION_NAME')"
              icon-name="far fa-pencil"
            />
          </v-card-actions>
        </v-card>
      </BaseHasPermission>
    </div>
    <BaseLoadingProgress v-else />
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import GoToPageButtonIcon from "@components/ButtonIcon/GoToPageButtonIcon";
import DeleteAction from "@components/ButtonIcon/DeleteAction/DeleteAction";
import AssignRolesTable from "@components/AssignTables/Roles/AssignRolesTable";
import { mapActions } from "vuex";

export default {
  name: "UserInvitationShow",
  components: {
    AssignRolesTable,
    DeleteAction,
    GoToPageButtonIcon,
    ModuleSubPage,
  },

  data() {
    return {
      userInvitationId: parseInt(this.$route.params.id, 10),
      isLoading: false,
      userInvitation: {
        id: null,
        salutation: null,
        firstName: null,
        lastName: null,
        email: null,
        navisionNumber: null,
        createdAt: null,
        updatedAt: null,
        createdBy: {},
        roles: [],
      },
    };
  },

  computed: {
    fullName() {
      return `${this.userInvitation.firstName} ${this.userInvitation.lastName}`;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async getUserInvitation() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.userManagement.showUserInvitation(this.userInvitationId);

        this.userInvitation = data.data;
      } catch (err) {
        console.error(err);
        await this.notify({
          type: "error",
          message: "USER_MANAGEMENT.USER_INVITATIONS.EDIT.MESSAGES.LOADING_ERROR",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async changeRoute() {
      try {
        await this.$router.push({ name: "management-user-invitation-list" });
      } catch {
        return;
      }
    },

    async deleteUserInvitation(userInvitationId) {
      this.isLoading = true;

      try {
        await this.$axios.userManagement.deleteUserInvitation(userInvitationId);
        this.notify({
          type: "success",
          message: "USER_MANAGEMENT.USER_INVITATIONS.DELETE_ACTION.SUCCESS_MESSAGE",
        });

        this.changeRoute();
      } catch (err) {
        console.error(err);

        this.notify({
          type: "error",
          message: "USER_MANAGEMENT.USER_INVITATIONS.DELETE_ACTION.ERROR_MESSAGE",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.getUserInvitation();
  },
};
</script>
